import React, { useEffect } from 'react';

const Pricing = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/buy-button.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="header">Upgrade</h1>
        <div className="center">
          <stripe-buy-button
            buy-button-id="buy_btn_1PATHNBY7L5WREAJN9t4vksl"
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
          >
          </stripe-buy-button>
        </div>
      </header>
    </div>
  );
};

export default Pricing;
