import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBoxOpen, FaDollarSign, FaCodeBranch, FaRocket, FaCloud, FaComment, FaBars } from 'react-icons/fa';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ position: 'fixed', top: '0', left: '0', height: '100vh', width: isOpen ? '200px' : '0', backgroundColor: '#17072B', color: 'white', display: 'flex', flexDirection: 'column', padding: '20px', transition: 'width 0.3s ease' }}>
      <button onClick={toggleSidebar} style={{ backgroundColor: isOpen ? '#2c003e' : 'transparent', border: 'none', color: 'white', fontSize: '2em', position: 'absolute', top: '10px', right: '10px' }}>
        <FaBars />
      </button>
      {isOpen && (
        <>
          <h2 style={{ color: 'white' }}><FaBoxOpen color="white" size="2em" /> Services</h2>
          <Link to="/request" className="sidebar-link">
            <FaDollarSign color="white" size="1.5em" /> Bids
          </Link>
          <Link to="/workspace" className="sidebar-link">
            <FaCodeBranch color="white" size="1.5em" /> Workspaces
          </Link>
          <Link to="/deploy" className="sidebar-link">
            <FaRocket color="white" size="1.5em" /> Deployments
          </Link>
          <Link to="/database" className="sidebar-link">
            <FaCloud color="white" size="1.5em" /> Databases
          </Link>
          <Link to="/chat" className="sidebar-link">
            <FaComment color="white" size="1.5em" /> AI Chat
          </Link>
          <Link to="/api" className="sidebar-link">
            <FaCodeBranch color="white" size="1.5em" /> API
          </Link>
        </>
      )}
    </div>
  );
}

export default Sidebar;