// NavBar.js
import { Navbar, Nav, Button, Dropdown } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import ThemeContext from '../ThemeContext';
import { useContext } from 'react';
import { FaSun, FaMoon, FaDollarSign, FaHome, FaBook, FaCloud, FaList, FaSignInAlt, FaRocket, FaComment, FaPencilAlt, FaCog, FaCodeBranch, FaGithub, FaLinkedin, FaBitbucket, FaGitlab } from 'react-icons/fa';
import '../App.css'; 

function NavBar({ session }) {
    const { theme, toggleTheme } = useContext(ThemeContext);

    return (
        <Navbar bg={theme} variant={theme} expand="lg">
            <Navbar.Brand as={NavLink} to="/">
                <img src="/codepay.png" alt="Your Logo" height="30" className="fade-animation" style={{borderRadius: '50%'}} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <div className="d-flex ms-auto">
                    <div className="d-flex flex-row align-items-center">
                        {session ? (
                        <>
                            <Button 
                                href="/settings"
                                variant="dark" 
                                className="fade-animation" 
                                style={{backgroundColor: 'white',color: '#17072B', fontWeight: 'bold', fontSize: '18px', margin: '5px', border: '3px solid #2c003e', padding: '10px', textAlign: 'center'}}
                            >
                                <FaCog /> Settings
                            </Button>
                        </>
                        ) : (
                            <Button 
                                href="/auth"
                                variant="dark" 
                                className="fade-animation" 
                                style={{backgroundColor: 'white',color: '#17072B', fontWeight: 'bold', fontSize: '18px', margin: '5px', border: '3px solid #2c003e', padding: '10px', textAlign: 'center'}}
                            >
                                <FaSignInAlt /> Login
                            </Button>
                        )}
                    </div>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavBar;