import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button'; // Import Button from react-bootstrap
import { FaDollarSign } from 'react-icons/fa'; // Import FaDollarSign from react-icons

function Settings({ session }) {
    const [account, setAccount] = useState('');

    async function loadWeb3() {
        if (window.ethereum) {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            loadBlockchainData();
        }
        else {
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    async function loadBlockchainData() {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        setAccount(accounts[0]);
    }

    return (
        <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '50vh', width: '50%', fontSize: '20px' }}>
            <h2>Settings</h2>
            <Button 
                href="/pricing"
                variant="dark" 
                className="fade-animation" 
                style={{backgroundColor: 'white',color: '#17072B', fontWeight: 'bold', fontSize: '18px', margin: '5px', border: '3px solid #2c003e', padding: '10px', textAlign: 'center'}}
            >
                <FaDollarSign /> Upgrade
            </Button>
            <p>Connected MetaMask Account: {account}</p>
            <button onClick={loadWeb3}>
                <img src="/mm_logo.png" alt="MetaMask Logo" style={{ width: '20px', height: '20px' }} />
                Connect MetaMask
            </button>
        </div>
    );
}

export default Settings;